<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("copyProducts.copyProductVariants") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-7">
              <div class="text-start m-2 custom-input">
                <div class="mb-3 whitebg-input">
                  <label class="form-label bold-12 text-gray">{{
                    $t("copyProducts.copyFrom")
                  }}</label>
                  <v-select
                    v-model:options="products"
                    v-on:search="(s, k) => fetchProducts(s, k)"
                    v-model="from"
                    :filterable="false"
                    :placeholder="$t(`copyProducts.selectProduct`)"
                    :reduce="(c) => c.variant_id"
                    :getOptionKey="(c) => c.variant_id"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching && search.length > 2">
                        {{ $t("copyProducts.noProductsFoundFor") }}
                        <em>{{ search }}</em
                        >.
                      </template>
                      <span v-else>
                        {{
                          $t("copyProducts.startTypingToSearchForAProduct")
                        }}</span
                      >
                    </template>
                    <template v-slot:selected-option="option">
                      <div class="row">
                        <div class="col-auto px-0">
                          <img
                            :src="option?.image"
                            v-if="
                              option?.image !== null &&
                              option?.image !== undefined
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else
                            name="no-image"
                            class="product-image"
                          />
                        </div>
                        <div class="col">
                          <div>
                            <b>{{ option.name }}</b>
                          </div>
                          <div>
                            <span
                              v-for="char in option.caracteristics"
                              :key="char"
                              class="me-2"
                            >
                              {{ char }}
                            </span>
                            <span class="me-2">
                              {{ option.serial }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <span class="me-2">
                        {{ option.serial }}
                      </span>
                      <span
                        v-for="char in option.caracteristics"
                        :key="char"
                        class="me-2"
                      >
                        {{ char }}
                      </span>
                      <b>{{ option.name }}</b>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("copyProducts.copyTo")
                }}</label>
              </div>
              <div v-for="item in variants" :key="item.id" class="row">
                <div class="col-3">{{ item.serial }}</div>
                <div class="col-3" v-for="cs in characteristics" :key="cs.id">
                  {{
                    cs.characteristics_values.find(
                      (e) => e.id === item["characteristics_" + cs["id"]]
                    )?.value
                  }}
                </div>
                <div class="col-3">{{ item.sku }}</div>
              </div>
            </div>
            <div class="col-5 mt-2">
              <div class="mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("copyProducts.copyRows")
                }}</label>
              </div>
              <div class="custom-input" v-for="row in copyRows" :key="row">
                <label class="cstm-check d-flex">
                  <span class="regular-12 text-black mt-3">{{ row.name }}</span>
                  <input type="checkbox" v-model="row.selected" />
                  <span class="checkmark mt-3"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="copyProducts"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("copyProducts.copy") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import BaseIcon from "../icons/BaseIcon.vue";
const bootstrap = require("bootstrap");

export default {
  name: "CopyProducts",
  props: {
    modelValue: Array,
    copyRows: Array,
    characteristics: Array,
    show: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      myModal: null,
      success: false,
      loadAddBtn: false,
      lastSearchId: null,
      products: [],
      from: null,
    };
  },
  computed: {
    variants() {
      return this.modelValue.filter((e) => e.type === 2 || e.type === 1);
    },
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
  },
  methods: {
    showModal() {
      this.myModal.show();
    },
    copyProducts() {
      http
        .fetch(`/products/products/copy`, {
          from: this.from,
          to: this.variants.map((e) => e.variantId ?? e.id),
          rows: this.copyRows.filter((e) => e.selected),
        })
        .then(() => {
          this.myModal.hide();
        });
    },
    fetchProducts(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();
      this.lastSearchId = lastSearchId;
      http
        .fetch(`/products/products/search`, { q: search })
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            this.products = data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
  },
  components: { BaseIcon },
};
</script>
